import './modules/common';
import './modules/slider';

const headerBar = document.getElementById('headerBar');
const splash = document.getElementById('splash');


if (splash){
  document.body.classList.add("index");

  if (window.matchMedia('(max-width: 1200px)')) {
    window.onscroll = function() {
      if (document.body.scrollTop > headerBar.offsetHeight || document.documentElement.scrollTop > headerBar.offsetHeight) {
        headerBar.classList.remove('header--transparent');
      } else {
        headerBar.classList.add('header--transparent');
      }
    }
  }
}

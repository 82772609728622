import Swiper from 'swiper';

if (window.matchMedia('(max-width: 1199px)').matches) {
  
  let indexSwiper = new Swiper('#indexSwiper', {
    slidesPerView: 'auto',
    spaceBetween: 20,
    slidesOffsetAfter: 10,
    slidesOffsetBefore: 10,
    grabCursor: true,
  
    breakpoints: {
      768: {
        slidesOffsetAfter: 20,
        slidesOffsetBefore: 20,
      },
      992: {
        slidesOffsetAfter: 0,
        slidesOffsetBefore: 0,
      },
    },
  });
}

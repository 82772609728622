import MmenuLight from 'mmenu-light';
import { Fancybox } from "@fancyapps/ui";

/** 
 *  Fix safari ios15 height
 */
document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);

/**
 *  Mobile menu 
 */
const mmenu = new MmenuLight(
  document.querySelector('#menu'),
  '(max-width: 1200px)',
);

const navigator = mmenu.navigation({
  'title': 'Меню',
});

const drawer = mmenu.offcanvas({});
    
document.querySelector('#showMenuButton')
  .addEventListener('click', function(e) {
    e.preventDefault();
    drawer.open();
  });

document.querySelector('#closeMenuButton')
  .addEventListener('click', function(e) {
    e.preventDefault();
    drawer.close();
  });

/** 
 *  Accordion 
 */
document.querySelectorAll('.accordion').forEach(function(el) {
  el.querySelector('.accordion__toggler')
    .addEventListener('click', function(e) {
      e.preventDefault();
      el.classList.toggle('accordion--opened');
    });
});

// Fancybox
Fancybox.bind('[data-fancybox]', {
  compact: true,
  Toolbar: {
    display: {
      left: [],
      middle: [],
      right: [],
    },
  },
});